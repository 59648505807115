<template>
  <div :class="['developmentonmap', 'item-flex', 'paddingbox', { active: active }]">
    <span id="title">{{ development.title }}</span>
    <div v-if="development.icon != null" id="img-development">
      <img
        draggable="false"
        :src="`${$baseURL}/assets/${development.icon.id}`"
      />
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "developmentonmap",
  props: ["development", "index"],
  setup(props) {
    const store = useStore();
    return {
      active: computed(() => {
        return store.state.developments.active_development_index == props.index;
      }),
    };
  },
};
</script>
<style lang="scss">
@import "../styles/vars.scss";
.developmentonmap {
  margin: 0.5em;
  border: solid $blue 1px;
  cursor: pointer;
  #img-development {
    width: 100%;
    img {
      margin: auto;
      width: 100%;
    }
  }
}
.active {
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.15);
}
</style>