<template>
  <div v-if="show" class="error-view">
    <div class="error-box">
      <h3>{{ error_title }}</h3>
      <p id="code">Error code:{{ error_code }}</p>
      <p id="string" v-html="error_string"></p>
      <button @click="reloard">Webseite neu laden</button>
      <button @click="send_email">Email an den Entwickler</button>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  props: {},
  name: "error",
  setup() {
    const store = useStore();
    function reloard() {
      location.reload();
    }
    function send_email() {
      window.open(`mailto:help@innovacty.de?subject=Error: ${store.state.error.error_title}&body=${JSON.stringify(store.state.error.error)}`);
    }
    return {
      error_title: computed(() => store.state.error.error_title),
      error_string: computed(() => store.state.error.error_string),
      error_code: computed(() => store.state.error.error_code),
      show: computed(() => store.state.error.show),
      reloard,
      send_email
    };
  },
};
</script>
<style lang="scss">
.error-view {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 0, 0, 0.753);
  z-index: 1001;
}
.error-box {
  background-color: white;
  border-radius: 10px;
  width: 40%;
  padding: 1em;
  button {
    width: 100%;
  }
  p,button {
      margin: 0.3em 0 0.3em 0;
  }
  #string {
      background-color: rgb(255, 53, 53);
      color: white;
      padding: 0.3em;
      border-radius: 5px;
  }
}
</style>