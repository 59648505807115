import './styles/index.scss';
import './styles/icon.scss'
import { createApp } from 'vue'
import { setupI18n } from './i18n'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import SYNC_WEB from './Plugins/sync_web'
import { baseURL } from './api'
const i18n = setupI18n()

const app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)
app.use(new SYNC_WEB(store, router, i18n))

app.config.globalProperties.$baseURL = baseURL
app.mount('#app')