<template>
  <article id="about">
    <h2>{{ about.title }}</h2>
    <div v-html="about.description"></div>
    App Version: {{version}}<br>
    Api Endpoint: {{BaseURL}} 
  </article>
</template>

<script>
import { computed ,ref } from "vue";
import { useStore } from "vuex";
import { baseURL } from "../api"

export default {
  name: "about",
  setup() {
    const store = useStore();
    const BaseURL = ref(baseURL)
    return {
      about: computed(() => store.state.sidenav.about),
      version: computed(() => store.state.sidenav.version),
      BaseURL
    };
  },
};
</script>

<style>
</style>