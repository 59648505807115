import { http } from '../api.js'

export default {
    state: {
        buildings: [],
        active_building_id: 0,
        active_building: {},
        sidecard: false,

    },
    mutations: {
        add_buildings(state, payloard) {
            state.buildings = payloard
        },
        set_cardbuildings(state, payloard) {
            state.sidecard = payloard
        },
        set_current_building(state, payloard) {
            state.active_building_id = payloard
            state.active_building = state.buildings[payloard]
        }
    },
    actions: {
        async get_buildings({ commit, dispatch, rootState }) {
            try {
                let buildings = await http.post('graphql', {
                    query: `
                        {
                               buildings(filter: {status: {_eq: "published"}}) {
                                   id,
                                   name,
                                   size,
                                   start_money,
                                   small_icon,
                                   big_icon {
                                       id
                                   },
                                   positionx,
                                   positiony,
                                   translations(filter: { languages_code: {code: { _eq: "${rootState.language}"}}} ) {
                                       description
                                   }
                               }
                        
                     } 
                    `
                })
                commit('set_number_of_buildings', buildings.length)
                buildings = buildings.data.data.buildings
                let obj = {};
                let attributes = new RegExp('<([a-z][a-z0-9]*)[^>]*?(/?)>','gm')
                for (let el in buildings) {
                    obj[buildings[el].id] = buildings[el]
                    if (buildings[el].translations != null && buildings[el].translations.length != 0) {
                        for (const [key, value] of Object.entries(buildings[el].translations[0])) {
                            obj[buildings[el].id][key] = value
                        }
                        obj[buildings[el].id].description = obj[buildings[el].id].description.replace(attributes, "<$1$2>");
                        delete obj[buildings[el].id].translations
                    }
                }
                console.log(obj)
                commit('add_buildings', obj)
            } catch (e) {
                dispatch('disabele_loarding')
                dispatch('fire_error', e)
            }
        },
        async prepair_buildings_for_gameplay({ state, commit }, obj) {
            for (const building in obj) {
                let money = 0;
                if (state.buildings[building] != undefined) {
                    money = state.buildings[building].start_money
                    console.log(`geld: ${money}`)
                }
                commit('set_building', {
                    id: building,
                    money,
                    size: state.buildings[building].size,
                    number_of_developments: obj[building].length
                })
            }
        }
    }

}