import { http, baseURL } from '../api.js'
export default {
    state: {
        assets: []
    },
    mutations: {
        set_assets(state, payloard) {
            state.assets = payloard
        }
    },
    actions: {
        async get_assets({ dispatch, commit }) {
            try {
                let files = await http.get('files')
                let obj = {}
                for (let file of files.data.data) {
                    obj[file.title] = file
                }
                commit('set_assets', obj)
            } catch (e) {
                dispatch('disabele_loarding')
                dispatch('fire_error', e)
            }
        }
    }, getters: {
        downloard_url: (state) => (title) => {
            if (typeof state.assets[title] !== 'undefined') {
                return `${baseURL}/assets/${state.assets[title].id}`
            } else {
                return "error"
            }
        }
    }
}