import { http } from '../api.js'

export default {
    state: {
        developments: [],
        active_development_index: 0,
        active_development: {},
        sidecard: false,
    },
    mutations: {
        set_developments(state, payloard) {
            state.developments = payloard
        },
        set_active_development(state, payloard) {
            state.active_development_index = payloard.index
            state.active_development = state.developments[payloard.buildingid][payloard.index]
        },
        setsidecard_development(state, payloard) {
            state.sidecard = payloard
        }
    }, actions: {
        async get_developments({ dispatch, commit, rootState }) {
            try {
                let developments = await http.post('graphql', {
                    query: `
                    { 
                        developments(filter: {status: {_eq: "published"}}) {
                            id,
                            status,
                            icon {
                              id
                            },
                            importance,
                            translations(filter: {languages_code: {code: {_eq: "${rootState.language}"}}}) {
                                title,
                                description
                            },
                            building {
                                id
                            },
                            connection {
                                materials_id {
                                    id
                                }
                            }
                        },
                    }
                    
                `
                })
                developments = developments.data.data.developments
                console.log(developments)
                let obj = {};
                for (let el in developments) {
                    developments[el].connection = Array.from(developments[el].connection, x => parseInt(x.materials_id.id))
                    developments[el].aktiv = 0
                    if (developments[el].translations != null && developments[el].translations.length !=0) {
                        for (const [key, value] of Object.entries(developments[el].translations[0])) {
                            developments[el][key] = value
                        }
                    }

                    if (obj[developments[el].building.id] == undefined) obj[developments[el].building.id] = []
                    obj[developments[el].building.id].push(developments[el])


                    delete obj[developments[el].building.id].id
                }
                console.log(obj)
                dispatch('prepair_buildings_for_gameplay', obj)
                commit('set_developments', obj)
            } catch (e) {
                dispatch('disabele_loarding')
                dispatch('fire_error', e)
            }

        }

    }
}