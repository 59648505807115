import { createStore } from 'vuex'
import characters from './characters'
import buildings from './buildings'
import developments from './developments'
import materials from './materials'
import sidenav from './sidenav'

import error from './error'
import game_status from './game_status'
import assets from './assets'
import helpbot from './helpbot'


//Plugins



export default createStore({
    state: {
        language: 'en',
        loarding: false,
        timer: undefined,
        mapmode: false
    },
    mutations: {
        show_loarding(state, payloard) {
            state.loarding = payloard
        }, setlocale(state, payloard) {
            console.log(payloard)
            state.language = payloard
        }, setmapmode(state, payloard) {
            state.mapmode = payloard
        }
    },
    actions: {
        async fire_loarding({ commit, state }) {
            if (state.timer != undefined) {
                clearTimeout(state.timer)
            }
            state.timer = setTimeout(() => {
                commit('show_loarding', true)
            }, 500);
        },
        async disabele_loarding({ commit, state }) {
            clearTimeout(state.timer)
            commit('show_loarding', false)
        }
    },
    modules: {
        characters,
        buildings,
        developments,
        materials,
        sidenav,
        error,
        game_status,
        assets,
        helpbot
    }
})