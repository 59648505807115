import { http } from '../api.js'

export default {
    state: {
        materials: [],
        selected_material_index: 0,
        selected_material_id: 0
    },
    mutations: {
        set_materials(state, payloard) {
            state.materials = payloard
        },
        set_active_material(state, payloard) {
            state.selected_material_index = payloard.index
            state.selected_material_id = payloard.id
        }
    }, actions: {
        async get_materials({ dispatch, commit, rootState }) {
            try {
                let materials = await http.post('graphql', {
                    query: ` {
                      materials(filter: {status: {_eq: "published"}}) {
                        id,
                        status,
                        prize,
                        material_health,
                        material_reutilization,
                        renewable_energy_carbon_management,
                        water_stewardship,
                        social_fairness,
                        overall_rating,
                        translations(filter: {languages_code: {code: {_eq: "${rootState.language}"}}}) {
                          name
                          description
                        }, 
                        icon {
                            id
                        },
                      },
                    
                  }`
                })
                materials = materials.data.data.materials
                let obj = {};
                for (let el in materials) {
                    obj[materials[el].id] = materials[el]
                    if (materials[el].translations != null && materials[el].translations.length !=0) {
                        for (const [key, value] of Object.entries(materials[el].translations[0])) {
                            obj[materials[el].id][key] = value
                        }
                        delete obj[materials[el].id].translations
                    }
                }
                console.log(obj)
                commit('set_materials', obj)
            } catch (e) {
                dispatch('disabele_loarding')
                dispatch('fire_error', e)
            }
        }
    },

}