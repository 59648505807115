import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

export const SUPPORT_LOCALES = ['en', 'de']
export const SUPPORT_LOCALES_FULLLENGTH = { 'en': "English", "de": "Deutsch" }
export const DEFAULT_LOCALE = 'en'

export function setupI18n() {
    const options = {
        locale: 'en',
        fallbackLocale: 'en'
    }
    const i18n = createI18n(options)
    return i18n
}

export function setI18nLanguage(i18n, locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
    // load locale messages with dynami import
    const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./translations/${locale}.json`
    )
    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default)
    return nextTick()
}