<template>
  <div :class="['card', ex_classes, { cardshowback: showmore }]">
    <div :class="['card-inner', { aktiv: aktiv }]">
      <div class="flip-card-front">
        <div v-if="more" @click="togglemore" :class="['icon',{ 'info': !showmore }, { 'cross': showmore }, 'topbutton']"></div>
        <div class="card-img"><slot name="img"></slot></div>
        <p class="card_title" v-html="card_title"></p>
        <div class="main"><slot name="main"></slot></div>
      </div>
      <div class="flip-card-back">
        <div class="main backtext"><slot name="more"></slot></div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  props: {
    card_title: String,
    ex_classes: String,
    aktiv: Boolean,
    more: Boolean,
  },
  setup() {
    const showmore = ref(false);
    function togglemore() {
      showmore.value = !showmore.value;
    }
    return {
      showmore,
      togglemore,
    };
  },
};
</script>
<style lang="scss">
.card-img {
  width: 100%;
  text-align: center;
  max-width: 300px;
  img {
    width: 50%;
  }
}
.cardshowback .card-inner {
  transform: rotateY(180deg);
}
.card {
  perspective: 1000px;
  width: 300px;
  .card-inner {
    cursor: pointer;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.5s;
    transform-style: preserve-3d;
  }
  .flip-card-back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
  }
  .flip-card-front,
  .flip-card-back {
    padding: 0.7em;
    width: 100%;
    backface-visibility: hidden;
  }
  .card_title {
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    padding: 1em 0 1em 0;
  }
  .topbutton {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    padding: 12px;
  }
  .backtext {
    margin-top: 2em;
  }
}
.main {
  font-size: 0.9em;
  color: #6d6d6d;
}

.aktiv {
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.2), 0 6px 40px 0 rgba(0, 0, 0, 0.19) !important;
}
</style>