export default {
    state: {
        finished_buildings: 0,
        number_of_buildings: 0,
        buildings: {},
        types: ['material_health', 'material_reutilization', 'renewable_energy_carbon_management', 'water_stewardship', 'overall_rating']
    },
    mutations: {
        set_number_of_buildings(state, payloard) {
            state.number_of_buildings = payloard
        }, set_building(state, payloard) {
            state.buildings[payloard.id] = payloard
            state.buildings[payloard.id].finished = false
            state.buildings[payloard.id].material_health = 0
            state.buildings[payloard.id].material_reutilization = 0
            state.buildings[payloard.id].renewable_energy_carbon_management = 0
            state.buildings[payloard.id].water_stewardship = 0
            state.buildings[payloard.id].solcial_fairness = 0
            state.buildings[payloard.id].overall_rating = 0
            state.buildings[payloard.id].materials = []
            state.buildings[payloard.id].developmets = []
        },
        add_material_to_building(state, payloard) {
            state.buildings[payloard.id].materials.push(payloard.material)
            state.buildings[payloard.id].developmets.push(payloard.dev_id)
            console.log(state.buildings[payloard.id].materials)
        },
        change_material_of_building(state, payloard) {
            const pos = state.buildings[payloard.id].developmets.indexOf(payloard.dev_id)
            state.buildings[payloard.id].materials[pos] = payloard.material
        }
    }, actions: {
        add_material_to_building({ commit, state }, p) {
            //const id = payloard.building_id
            if (!(p.dev_id in state.buildings[p.build_id].developmets)) {
                commit('add_material_to_building', { id: p.build_id, dev_id: p.dev_id, material: p.material })
            } else {
                commit('change_material_of_building', { id: p.build_id, dev_id: p.dev_id, material: p.material })
            }
        }
    }, getters: {
        get_evaluation: (state) => (building) => {
            let num = {};
            for (let type of state.types) {
                num[type] = 0
            }
            if (state.buildings[building] != undefined) {
                for (let material in state.buildings[building].materials) {
                    for (const type of state.types) {
                        num[type] += (state.buildings[building].materials[material][type] * state.buildings[building].materials[material].importance) / 100
                        //console.log(state.buildings[building].materials[material].importance)
                        //    console.log(num[type])
                    }
                }
                return num
            } else {

                return num
            }
        }, get_money: (state) => (building) => {
            let num = 0
            if (state.buildings[building] != undefined) {
                num = state.buildings[building].money
                for (let material in state.buildings[building].materials) {
                    num -= state.buildings[building].materials[material].prize * state.buildings[building].size
                }
            }
            return num
        }
    }
}