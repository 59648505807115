<template>
  <article id="start_page">
    <fullscreenslide
      :title="$t('start.header')"
      :subtitle="$t('start.description')"
      :back="false"
      @forward="start()"
      :buttontextforward="$t('start.button')"
    >
      <template v-slot:img>
        <img :src="cover" />
      </template>
      <template v-slot:box>
        <div class="select-lang">
          {{ $t('start.lang') }}
          <select v-model="selected_locale" @change="setlocale">
            <option v-for="(locale, index) in localesshort" :key="index" :value="locale" :selected="locale === selected_locale">
              {{ localesfulllength[locale] }}
            </option>
          </select>
        </div>
      </template>
    </fullscreenslide>
  </article>
</template>
<script>
import { ref } from "vue";
import { SUPPORT_LOCALES,SUPPORT_LOCALES_FULLLENGTH } from "../i18n";
import fullscreenslide from "../components/fullscreenslide";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "start_page",
  components: {
    fullscreenslide,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const localesshort = ref(SUPPORT_LOCALES);
    const localesfulllength = ref(SUPPORT_LOCALES_FULLLENGTH);
    var selected_locale = ref(store.state.language)
    function setlocale() {
      console.log(selected_locale.value);
      if (localStorage != undefined) {
        console.log(selected_locale.value)
        localStorage.setItem("locale", selected_locale.value);
      } else {
         alert("too old browser")
      }
      router.go(0);
    }
    async function start() {
      await store.dispatch("fire_helpbot", {
        to: { name: "choose_character" },
        from: { name: "start" },
      });
    }
    return {
      start,
      cover: computed(() => store.getters.downloard_url("Cover")),
      localesshort,
      localesfulllength,
      setlocale,
      selected_locale
    };
  },
};
</script>

<style lang="scss">
#start_page {
  height: 100%;
}
.select-lang {
  text-align: center;
}
</style>