<template>
  <div id="fullscreenslide">
    <div id="title-tutorial">
      <h1 class="versal">{{ title }}</h1>
      <h3 class="versal">{{ subtitle }}</h3>
    </div>
    <div id="imgbox"><slot name="img"></slot></div>
    <div id="bottombox">
      <div><slot name="box"></slot></div>
    </div>
    <div v-if="!back" id="button-box">
      <button class="btn versal" @click="$emit('forward')">
        {{ buttontextforward }}
      </button>
    </div>
    <teleport v-else to="footer">
      <div class="nav-footer">
        <a class="back" @click="$emit('back')">{{ buttontextback }}</a>
        <a class="forward" @click="$emit('forward')">
          {{ buttontextforward }}
        </a>
      </div>
    </teleport>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    subtitle: String,
    buttontextback: String,
    buttontextforward: String,
    back: Boolean,
    backto: String,
    forwardto: String,
  },
  emits: ["back", "forward"],
};
</script>
<style lang="scss">
@import "../styles/vars.scss";
#fullscreenslide {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#title-tutorial {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 3.5em;
    font-weight: 300;
  }
  h3 {
    margin-top: 0.5em;
    color: $blue;
  }
}
#imgbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    max-width: 400px;
  }
}
#bottombox {
  margin-top: 1em;
  font-size: 0.9em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    width: 100%;
    max-width: 650px;
  }
}
</style>