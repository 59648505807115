export default {
    state: {
        show: false,
        error_code: 0,
        error_title: "",
        error_string: "",
        error: undefined
    },
    mutations: {
        set_error(state, payloard) {
            if (!payloard.show) {
                state.show = false
                return
            }
            state.show = true
            state.error_code = payloard.error_code
            state.error_string = payloard.error_string
            state.error_title = payloard.error_title
            state.error = payloard.error
        }
    },
    actions: {
        fire_error({ commit }, payloard) {
            let error_title;
            let error_string;
            let error_code = 404;
            if (payloard.response) {
                error_code = payloard.response.status
                error_string = payloard.response.data
                error_title = payloard.message
                console.log(error_string)
                if (Array.isArray(error_string.errors)) {
                    let arr = "";
                    for (let el in error_string.errors) {
                        arr += `${error_string.errors[el].message}</br>`
                        arr += `<i>${JSON.stringify(error_string.errors[el].extensions)}</i>`
                    }
                    error_string = arr
                }
            } else if (payloard.request) {
                error_title = payloard.message
                error_string = payloard.request

            } else {
                error_title = payloard.message
                error_string = payloard.message

            }
            console.log(payloard)
            commit('set_error', { show: true, error_code, error_title, error_string, error: payloard })
        }
    }
}