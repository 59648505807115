<template>
  <div id="hamburger" @click="setstatus" :class="['icon', 'hamburger', {'hamburger-activ':show_nav }]"></div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "hamburger_menue",
  setup() {
    const store = useStore();
    function setstatus() {
      if (store.state.sidenav.show_nav) {
        store.commit("set_show_nav", false);
        document.querySelector(".side-nav").style.width = "0";
        document.querySelector("main").style.marginRight = "0px";
      } else {
        store.commit("set_show_nav", true);
        document.querySelector(".side-nav").style.width = "30%";
        document.querySelector("main").style.marginRight = "30%";
      }
    }
    return {
      setstatus,
      show_nav: computed(() => store.state.sidenav.show_nav),
    };
  },
};
</script>
<style lang="scss">
#hamburger {
  cursor: pointer; 
}
</style>