import { createRouter, createWebHashHistory } from 'vue-router'
import start_page from '../views/start_page.vue';
import choose_character from '../views/choose_character'
const map = () => import('../views/map.vue') 

import devellopment from '../views/devellopment'
import building_view from '../views/building_view';

import about from '../views/about'
import help from '../views/help'

import tutorial from '../views/tutorial'
import notfound from '../views/notfound'

const routes = [{
    name: 'start',
    path: '/',
    component: start_page,
    meta: { transition: 'fade-page', data_neded: [{ v: 'assets', m: 'assets' }] },
},
{
    name: 'about',
    path: '/about',
    component: about,
    meta: {data_neded: [{ v: 'about', m: 'sidenav' }]}
},
{
    name: 'help',
    path: '/help',
    component: help,
    meta: {data_neded: [{ v: 'help', m: 'sidenav' }]}
},
{
    name: 'choose_character',
    path: '/choose_character',
    component: choose_character,
    meta: { transition: 'fade-page', data_neded: [{ v: 'characters', m: 'characters' }], helpbot: "before_characters" },
},
{
    name: 'map',
    path: '/map',
    component: map,
    meta: { transition: 'fade-page', data_neded: [{ v: 'buildings', m: 'buildings' }], map: true, helpbot:"building_view" },
},
{
    name: 'building',
    path: '/building/:build_id',
    component: building_view,
    meta: { transition: 'fade-page', data_neded: [{ v: 'buildings', m: 'buildings' }, { v: 'developments', m: 'developments' }] },
}, {
    name: 'development',
    path: '/development/:build_id/:dev_id',
    component: devellopment,
    meta: { transition: 'fade-page', data_neded: [{ v: 'buildings', m: 'buildings' }, { v: 'materials', m: 'materials' }, { v: 'developments', m: 'developments' }] },
}, {
    name: 'tutorial',
    path: '/tutorial/:type/:id',
    component: tutorial
}, {
    path: "/:pathMatch(.*)*",
    component: notfound
}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router