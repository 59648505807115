<template>
  <loarding></loarding>
  <error></error>
  <sidenav></sidenav>
  <main :class="{ mapmode: mapmode }">
    <headercomp></headercomp>
    <router-view></router-view>
    <footer></footer>
  </main>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import headercomp from "./components/headercomp";
import loarding from "./components/loarding";
import error from "./components/error";
import sidenav from "./components/sidenav";


export default {
  components: {
    headercomp,
    loarding,
    error,
    sidenav,
  },
  setup() {
    const store = useStore();
    return {
      mapmode: computed(() => store.state.mapmode),
    };
  },
};
</script>
