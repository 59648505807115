<template>
  <div class="icon footprint"><div class="icon temp"></div></div>
</template>
<script>
export default {};
</script>
<style lang="scss">
  div.footprint { 
    position: relative;
    width: 200px;
    div.temp { display: block; position: absolute; left: -17px; bottom: -15px; padding: 15px; }
  }
</style>