<template>
  <article id="building_view" class="layer-1">
    <section id="floatingbox">
      <div
        class="item-flex topbox"
        v-if="buildings[$route.params.build_id].big_icon != null"
      >
        <img
          title="current building"
          draggable="false"
          :src="`${$baseURL}/assets/${
            buildings[$route.params.build_id].big_icon.id
          }`"
        />
        <span class="versal">{{ buildings[$route.params.build_id].name }}</span>
      </div>
      <developmentonmap
        v-for="(development, index) in developments[$route.params.build_id]"
        :key="development.id"
        :development="development"
        :index="index"
        @click="setactive(index)"
      ></developmentonmap>
    </section>
    <sidecard
      :title="active_development.title"
      :description="active_development.description"
      :showcard="sidecardactive"
      closebuttontext="choose material"
      @close="closesidecard"
      @go="choosematerial"
    ></sidecard>
  </article>
</template>

<script>
import developmentonmap from "../components/development-on-map";
import sidecard from "../components/side-card";
import { computed } from "vue";
import { useStore } from "vuex";

import { useRoute, useRouter } from "vue-router";

export default {
  components: { developmentonmap, sidecard },
  name: "building_view",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    function setactive(index) {
      store.commit("set_active_development", {
        index,
        buildingid: route.params.build_id,
      });
      store.commit("setsidecard_development", true);
    }
    function closesidecard() {
      store.commit("setsidecard_development", false);
    }
    function choosematerial() {
      store.commit("setsidecard_development", false);
      router.push({
        name: "development",
        params: {
          build_id: route.params.build_id,
          dev_id: store.state.developments.active_development_index,
        },
      });
    }
    return {
      buildings: computed(() => store.state.buildings.buildings),
      developments: computed(() => store.state.developments.developments),
      active_development: computed(
        () => store.state.developments.active_development
      ),
      sidecardactive: computed(() => store.state.developments.sidecard),
      setactive,
      closesidecard,
      choosematerial,
    };
  },
};
</script>

<style lang="scss">
#floatingbox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  .item-flex {
    width: 300px;
    padding: 0.5em;
  }
  .topbox {
    width: 200px;
    span {
      margin-top: 0.5em;
      display: block;
      text-align: center;
      white-space: nowrap;
    }
    img {
      width: 100%;
    }
  }
}
</style>