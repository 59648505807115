import { SUPPORT_LOCALES, setI18nLanguage , loadLocaleMessages, DEFAULT_LOCALE} from '../i18n'
class SYNC_WEB {
    constructor(vuex, router, i18n) {
        this.store = vuex
        this.router = router
        this.i18n = i18n
        this.router.beforeEach((to, from, next) => this.beforeach(to, from, next))
    }
    async beforeach(to, from, next) {
        this.store.dispatch('fire_loarding')
        let data_neded = to.meta.data_neded
        if (typeof data_neded === 'undefined') {
            data_neded = []
        }
        

        loadLocaleMessages(this.i18n, DEFAULT_LOCALE)
        let locale = localStorage.getItem('locale')
        // use locale if paramsLocale is not in SUPPORT_LOCALES
        if (!SUPPORT_LOCALES.includes(locale)) {
            locale = DEFAULT_LOCALE
        } else {
            this.store.commit("setlocale", locale)
        }
        // load locale messages
        if (!this.i18n.global.availableLocales.includes(locale)) {
          await loadLocaleMessages(this.i18n, locale)
        }
    
        // set i18n language
        setI18nLanguage(this.i18n, locale)

        data_neded.push({ v: 'helpbot', m: 'helpbot' }) //every view needs help !
        for (let el in data_neded) {
            if (data_neded[el] != "game_status") {
                await this.loard_web_data(data_neded[el])
            }
        }

        this.checkif_map(to.meta.map)
        this.store.dispatch('disabele_loarding')
        next()
    }
    checkdata(data_neded) {
        return new Promise((resolve) => {
            if (data_neded.m != undefined) {
                if (this.store.state[data_neded.m][data_neded.v] != undefined) {
                    if (this.store.state[data_neded.m][data_neded.v].length != 0) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                } else {
                    resolve(false)
                }
            } else {
                resolve(false)
            }
        })
    }
    checkif_map(ismap) {
        if (ismap != undefined) {
            this.store.commit('setmapmode', true)
        } else {
            if (this.store.state.mapmode) this.store.commit('setmapmode', false)
        }
    }
    async loard_web_data(data_neded) {
        if (!await this.checkdata(data_neded))
            await this.store.dispatch(`get_${data_neded.v}`)
        else
            console.log(`${data_neded.v} ist im cache`)
    }
    async loard_gamestatus() {
        console.log("game status")
    }
    install(app) {
        app.config.globalProperties.$sync_web = this.loard_gamestatus.bind(this)

    }
}
export default SYNC_WEB