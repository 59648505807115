<template>
  <div id="tutorial">
    <fullscreenslide
      :title="current.title"
      :subtitle="current.subtitle"
      :back="true"
      :buttontextback="t('helpbot.back')"
      :buttontextforward="t('helpbot.next')"
      @back="go(-1)"
      @forward="go(1)"
    >
    <template v-if="current.icon != null" v-slot:img>
        <img draggable="false" :src="`${$baseURL}/assets/${current.icon.id}`" />
    </template>
     <template v-if="typeof current.description !== 'undefined'" v-slot:box>
        <span v-html="current.description"></span>
    </template>
    </fullscreenslide>
  </div>
</template>

<script>
import fullscreenslide from "../components/fullscreenslide";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";

export default {
  name: "tutorial",
  components: {
    fullscreenslide,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();

    store.dispatch("restore", {
      type: route.params.type,
      pos: route.params.id,
    });
    function go(direction) {
      store.dispatch("helpbot_go", direction);
    }
    //const id = route.params.id;
    console.log(route.params.id)

    return {
      t,
      current: computed(() => store.getters.current_helpbot_page),
      go,
    };
  },
};
</script>

<style lang="scss">
#tutorial {
  height: 100%;
}
</style>