import { http } from '../api.js'

export default {
    state: {
        about: [],
        help: [],
        version: "",
        show_nav: false,
        backroute: ""
    },
    mutations: {
        set_about(state, payloard) {
            state.about = payloard
        },
        set_help(state, payloard) {
            state.help = payloard
        },
        set_version(state, payloard) {
            state.version = payloard
        },
        set_show_nav(state, payloard) {
            state.show_nav = payloard
        },
        set_backroute(state, payloard) {
            state.backroute = payloard 
        }
    },
    actions: {
        async get_about({ commit, dispatch, rootState }) {
            try {
                let about = await http.post("graphql", {
                    query: `{
                        about {
                          translations(filter: {languages_code: {code: {_eq: "${rootState.language}"}}}) {
                            title,
                            description
                          }
                        }
                    }`});
                dispatch('get_version')
                about = about.data.data.about;
                if (about.translations.length == 0) {
                    about.title = null
                    about.description = null
                    return
                }
                for (const [key, value] of Object.entries(about.translations[0])) {
                    about[key] = value
                }
                delete about.translations
                
                commit('set_about', about)
            } catch (e) {
                dispatch('disabele_loarding')
                dispatch('fire_error', e)
            }
        },
        async get_version({ dispatch, commit }) {
            try {
                let version = await http.get('https://innovacity.de/version.txt')
                commit('set_version', version.data)
            } catch (e) {
                dispatch('disabele_loarding')
                dispatch('fire_error', e)
            }
        },
        async get_help({ commit, dispatch, rootState }) {
            try {
                let help = await http.post("graphql", {
                    query: `{
                        help {
                          translations(filter: {languages_code: {code: {_eq: "${rootState.language}"}}}) {
                            title,
                            description
                          }
                        }
                    }`});
                help = help.data.data.help;
                if (help.translations.length == 0) {
                    help.title = null
                    help.description = null
                    return
                }
                for (const [key, value] of Object.entries(help.translations[0])) {
                    help[key] = value
                }
                delete help.translations
                console.log(help)
                commit('set_help', help)
            } catch (e) {
                dispatch('disabele_loarding')
                dispatch('fire_error', e)
            }
        }
    }
}