<template>
  <div id="side-card-box" class="layer-2">
    <transition name="sidecard">
      <div :class="['sidecard']" v-show="showcard">
        <div class="sidecard-inner paddingbox">
          <div class="top-nav">
            <img
              draggable="false"
              @click="$emit('close')"
              class="close-sidecard"
              src="../assets/cross.svg"
            />
            <h3>{{ title }}</h3>
          </div>
          <div class="side-card-beschreibung" v-html="description"></div>
          <button class="btn versal" @click="$emit('go')">
            {{ closebuttontext }}
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "side-card",
  props: {
    title: String,
    description: String,
    closebuttontext: String,
    showcard: Boolean,
  },
  emits: ["close", "go"],
};
</script>
<style lang="scss">
.sidecard {
  position: fixed;
  width: 30%;
  top: 0.5em;
  right: 0.5em;
  background-color: white;
  height: calc(100% - 1em);
  z-index: 10000000;
  .sidecard-inner {
    height: 100%;
    width: 100%;
    position: relative;
    button {
      box-sizing: border-box;
      width: calc(100% - 1.4em);
      position: absolute;
      bottom: 0.7em;
      left: 0.7em;
    }
    .top-nav {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    h3 {
      margin-left: 0.5em;
      float: left;
    }
    .close-sidecard {
      float: left;
      width: 25px;
      cursor: pointer;
    }
    .side-card-beschreibung {
      margin-top: 1.5em;
    }
  }
}

.sidecard-enter-active {
  transition: all 0.4s ease-out;
}

.sidecard-leave-active {
  transition: all 0.4s ease-out;
}

.sidecard-enter-from,
.sidecard-leave-to {
  transform: translateX(300px);
  opacity: 0;
}
</style>