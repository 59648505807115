<template>
  <article id="help">
    <h2>{{ help.title }}</h2>
    <p v-html="help.description"></p>
  </article>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "help",
  setup() {
    const store = useStore();
    return {
      help: computed(() => store.state.sidenav.help),
    };
  },
};
</script>

<style>
</style>