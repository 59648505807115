<template>
  <div class="icon house"></div>
  <div class="icon house"></div>
  <div class="icon house"></div>
  <div class="icon house"></div>
</template>
<script>
export default {
    setup() {
        return {
          
        }
    }
};
</script>
<style lang="scss">
</style>