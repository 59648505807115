<template>
  <article id="devellopment">
    <div class="title-tutorial versal">
      <h1>{{ t("development.header") }}</h1>
    </div>
    <div class="materials row">
      <card
        v-for="(material_id, index) in development.connection"
        :key="material_id"
        :card_title="materials[material_id].name"
        :aktiv="active == material_id"
        ex_classes="col-2"
        @click="set_aktive_material({ index, id: material_id })"
      >
        <template v-if="materials[material_id].icon != null" v-slot:img>
          <img
            :src="`${$baseURL}/assets/${materials[material_id].icon.id}`"
          />
        </template>
        <template v-slot:main>
          <p v-html="materials[material_id].description"></p>
        </template>
      </card>
    </div>
    <div id="button-box">
      <button class="btn versal" @click="select()">
        {{ t("development.button")}}<span v-if="active != 0">{{ " "+materials[active].name }}</span>
      </button>
    </div>
  </article>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import card from "../components/card";

export default {
  name: "devellopment",
  components: { card },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const materials = computed(() => store.state.materials.materials);
    const development = computed(
      () =>
        store.state.developments.developments[route.params.build_id][
          route.params.dev_id
        ]
    );
    const active = computed(
      () => store.state.materials.selected_material_id
    );

    function set_aktive_material(payloard) {
      store.commit("set_active_material", {
        index: payloard.index,
        id: payloard.id,
      });
    }
    function select() {
      let selectetmaterial =
        materials.value[development.value.connection[development.value.aktiv]];
      selectetmaterial.importance = development.value.importance;
      store.dispatch("add_material_to_building", {
        dev_id: route.params.dev_id,
        build_id: route.params.build_id,
        material: selectetmaterial,
      });
      router.go(-1);
    }

    return {
      t,
      development,
      materials,
      set_aktive_material,
      select,
      active,
    };
  },
};
</script>

<style lang="scss">
#devellopment {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.materials {
  height: 70%;
  justify-content: center;
}
</style>