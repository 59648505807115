<template>
  <header class="flex headercomp">
    <div class="header-container-left">
      <finishedbuildings></finishedbuildings>
      <moneyindicator></moneyindicator>
    </div>
    <div class="header-container-right">
      <footprint></footprint>
      <hamburgermenue></hamburgermenue>
    </div>
  </header>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import hamburgermenue from "./hamburger_menue";
import footprint from "./footprint";
import moneyindicator from "./moneyindicator";
import finishedbuildings from "./finished-buildings";

export default {
  name: "headercomp",
  components: {
    hamburgermenue,
    footprint,
    moneyindicator,
    finishedbuildings,
  },
  setup() {
    const store = useStore();
    return {
      evaluation: computed(() => store.getters.get_evaluation(1)), //dei 1 ist nur ein platzhalter
      money: computed(() => store.getters.get_money(1)),
    };
  },
};
</script>

<style lang="scss">
header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
   z-index: 2000000;
  padding: 1em 0.5em;
  background-color: white;
  /*border-bottom: solid 1px silver;*/
}
div.header-container-left,
div.header-container-right {
  white-space: nowrap;
  div {
    display: inline;
    margin: 0 0.25em;
  }
}
div.header-container-left{ width: 60%; text-align: left; }
div.header-container-right { width: 40%; text-align: right; }
</style>