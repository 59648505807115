import { http } from '../api.js'

export default {
    state: {
        characters: [],
        my_character: '',
        selected_character: 0
    },
    mutations: {
        set_characters(state, payloard) {
            state.characters = payloard
        },
        chose_character(state, payloard) {
            state.my_character = payloard.name
            state.selected_character = payloard.index
        }
    },
    actions: {
        async get_characters({ commit, dispatch, rootState }) {
            try {
                let characters = await http.post("graphql", {
                    query: `{
                
                  characters(filter: {status: {_eq: "published"}}) {
                      id,
                      name,
                      icon {
                        id
                      },
                      translations(filter: { languages_code: {code: { _eq: "${rootState.language}"}}} ) {
                          description
                      }
                  }
                
              }    
              `,
                });
                characters = characters.data.data.characters;
                let attributes = new RegExp('<([a-z][a-z0-9]*)[^>]*?(/?)>','gm')
                for(const el in characters) {
                    if(characters[el].translations != null && characters[el].translations.length !=0) {
                        for(const [key,value] of Object.entries(characters[el].translations[0])) {
                            characters[el][key] = value
                        }
                        characters[el].description = characters[el].description.replace(attributes, "<$1$2>");
                        delete characters[el].translations
                    }
                }
                
                console.log(characters)
                commit('set_characters', characters)
            } catch (e) {
                dispatch('disabele_loarding')
                dispatch('fire_error', e)
            }
        }
    }
}