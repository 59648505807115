import { http } from '../api.js'
import router from '../router/index.js'
export default {
    state: {
        types: {'choose_character': 'before_characters', 'map':'map_view' },
        helpbot: [],
        finished: [],
        display: false,
        fullscreen: false,
        selected_type: Object,
        previous: Object,
        shouldredirectto: Object,
        pos: 0
    },
    mutations: {
        set_helpbot(state, payloard) {
            state.helpbot = payloard
        }, set_selected_type(state, payloard) {
            state.selected_type = state.helpbot[payloard.type]
            state.previous = payloard.from
            state.shouldredirectto = payloard.to
        }, setfullscreen(state, payloard) {
            state.fullscreen = payloard
        }, setpos(state, payloard) {
            state.pos = payloard
        }
    },
    actions: {
        async get_helpbot({ dispatch, commit, rootState }) {
            try {
                let helpbot = await http.post("graphql", {
                    query: `{
                          help_bot_types(filter: {status: {_eq: "published"}}) {
                            name,
                            helpbot(filter: {status: {_eq: "published"}}) {
                              id,
                              fullscreen,
                              show_one_time,
                              icon {
                                id
                              },
                              translations(filter: {languages_code: {code: {_eq: "${rootState.language}"}}}) {
                                title,
                                subtitle,
                                description
                              }
                            }
                          },
                    }    
                `,
                });
                let obj = {}
                console.log(helpbot.data)
                const helpbottypes = helpbot.data.data.help_bot_types
                for (let type of helpbottypes) {
                    if (type.helpbot != null) {
                        for (const index in type.helpbot) {
                            if (type.helpbot[index].translations != null && type.helpbot[index].translations.length !=0) {
                                for (const [key, value] of Object.entries(type.helpbot[index].translations[0])) {
                                    type.helpbot[index][key] = value
                                }
                            }
                        }
                    }
                    obj[type.name] = type
                }
                commit('set_helpbot', obj)
            } catch (e) {
                dispatch('disabele_loarding')
                dispatch('fire_error', e)
            }
        }, async fire_helpbot({ commit, state }, payloard) {
            if(typeof state.types[payloard.to.name] === 'undefined') return
            payloard.type = state.types[payloard.to.name]
            const type = payloard.type
            if (typeof state.helpbot[type] === 'undefined') return
            if (state.helpbot[type].helpbot.length == 0) return
            commit('set_selected_type', payloard)
            commit('setpos',0)
            if (state.helpbot[type].helpbot[0].fullscreen) {
                commit('setfullscreen', true)
                router.push({
                    name: 'tutorial', params: {
                        id: 0,
                        type: type
                    }
                })
            }
        }, async helpbot_go({ commit, state }, direction) {
            const newpos = state.pos + direction
            if (newpos < 0) {
                console.log(state.previous)
                router.push(state.previous)
            } else if (newpos > state.selected_type.helpbot.length-1) {
                console.log(state.shouldredirectto)
                router.push(state.shouldredirectto)
            } else {
                commit('setpos', newpos)
                if(state.selected_type.helpbot[newpos].fullscreen) {
                    router.push({name: 'tutorial', params: {
                        id: newpos,
                        type: state.selected_type.name
                    }})
                }
                
            }
        },
        async restore({ state, commit }, payloard) {
            if (typeof state.selected_type.helpbot == 'undefined' ||  state.selected_type.name != payloard.type) {
                commit('set_selected_type', {
                    type: payloard.type,
                })
                commit('setpos', parseInt(payloard.pos))
            }
        }
    }, getters: {
        current_helpbot_page(state) {
            return state.selected_type.helpbot[state.pos]
        }
    }
}