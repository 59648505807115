<template>
  <article id="error">
    <fullscreenslide
      :title="t('error.title')"
      :subtitle="t('error.subtitle')"
      :buttontextforward="t('error.button')"
      @forward="gohome()"
    ></fullscreenslide>
  </article>
</template>

<script>
import fullscreenslide from "../components/fullscreenslide";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default {
  name: "notfound",
  components: {
    fullscreenslide,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    function gohome() {
        router.push('/')
    }
    return {
      t,
      gohome,
    };
  },
};
</script>

<style lang="scss">
#error {
  height: 100%;
}
</style>