<template>
  <article id="choose_character">
    <div class="title-tutorial versal">
      <h1>{{ t("choose_charcter.header") }}</h1>
    </div>
    <div class="characters row">
      <card
        v-for="(character, index) in characters"
        :key="character.name"
        :card_title="character.name"
        ex_classes="col-3"
        :more="true"
        @click="set_character({ index, character: character.name })"
        :aktiv="selected_character == index"
      >
        <template v-if="character.icon != null" v-slot:img>
          <img :src="`${$baseURL}/assets/${character.icon.id}`" />
        </template>
        <template v-slot:main>
          <p v-html="character.description"></p>
        </template>
        <template v-slot:more> hallo Welt </template>
      </card>
    </div>
    <teleport to="footer">
      <div class="nav-footer">
        <router-link class="back" to="/">{{
          t("choose_charcter.back")
        }}</router-link>
        <a class="forward" @click="helpbot">{{
          t("choose_charcter.forward")
        }}</a>
      </div></teleport
    >
  </article>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import card from "../components/card";

export default {
  name: "choose_character",
  components: { card },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    function set_character(character) {
      store.commit("chose_character", character);
    }
    async function helpbot() {
      await store.dispatch("fire_helpbot", {
        to: { name: "map" },
        from: { name: "choose_character" },
      });
    }
    return {
      characters: computed(() => store.state.characters.characters),
      selected_character: computed(
        () => store.state.characters.selected_character
      ),
      set_character,
      helpbot,
      t,
    };
  },
};
</script>

<style lang="scss">
.characters {
  height: 70%;
  justify-content: center;
}
</style>