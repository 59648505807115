<template>
  <nav class="side-nav">
    <a v-if="backroute != ''" @click="backgame">{{
      $t("sidenav.game")
    }}</a>
    <a v-for="nav in navs" :key="nav" @click="navigate(nav)">{{
      $t(`sidenav.${nav}`)
    }}</a>
  </nav>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const navs = ["help", "about"];

    function navigate(to) {
      // if current route belonges to other game functionaleties
      if (!navs.includes(route.name))
        store.commit("set_backroute", route.fullPath);
      router.push({ name: to });
    }
    function backgame() {
      router.push(store.state.sidenav.backroute);
      store.commit("set_backroute", '');
    }
    const { t } = useI18n();
    return {
      navs,
      navigate,
      backroute: computed(() => store.state.sidenav.backroute),
      backgame,
      t,
    };
  },
};
</script>
<style lang="scss">
.side-nav {
  z-index: 0;
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #111;
  transition: 0.5s;
  overflow-x: hidden;
  padding-top: 60px;
  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  a:hover {
    cursor: pointer;
    color: #c2c2c2;
  }
}
</style>